import React, { Suspense, lazy } from 'react';
import { Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import Loader from './component/Loader/Loader';
import MainLayout from './layout/MainLayout';
import MinimalLayout from './layout/MinimalLayout';
import NavMotion from './layout/NavMotion';
import { getEmitter } from './adapters/restClient';

const AuthLogin = lazy(() => import('./views/Login'));

const DashboardDefault = lazy(() => import('./views/Dashboard/Default'));

const CreateBotView = lazy(() => import('./views/CreateBot'));
const CreateUserView = lazy(() => import('./views/CreateUser'));
const ListBotView = lazy(() => import('./views/ListBotView'));

const Routes = () => {
    const location = useLocation();
    const history = useHistory();

    const emitter = getEmitter();

    emitter.on('UNAUTHORIZED', () => { // TODO - do use a better way to handle this -- contexts...
        localStorage.removeItem('auth');
        history.push('/login');
    });

    if (!localStorage.getItem('auth') && location.pathname !== '/login') return <Redirect to="/login" />;

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <Switch>
                    {/* <Redirect exact from="/" to="/login" /> */}
                    <Route path={['/login']}>
                        <MinimalLayout>
                            <Switch location={location} key={location.pathname}>
                                {/* <NavMotion></NavMotion> */}
                                <Route path="/login" component={AuthLogin} />
                            </Switch>
                        </MinimalLayout>
                    </Route>
                    <Route
                        path={[
                            '/',
                        ]}
                    >
                        <MainLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <Route exact path="/" component={DashboardDefault} />
                                    <Route exact path="/create-bot" component={CreateBotView} />
                                    <Route exact path="/bots" component={ListBotView} />
                                    <Route exact path="/create-user" component={CreateUserView} />
                                </NavMotion>
                            </Switch>
                        </MainLayout>
                    </Route>
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
