

const axios = require('axios').default;
const EventEmitter = require('events');

let emitter = new EventEmitter();

const restClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

restClient.interceptors.request.use((request) => {
    const auth = localStorage.getItem('auth');
    let authParsed;
    try {
        authParsed = JSON.parse(auth);
    } catch (_) { }

    const bearerToken = (authParsed || {}).token;
    request.headers = {
        ...request.headers,
        Authorization: `Bearer ${bearerToken}`
    }
    return request;
});

restClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        return getEmitter().emit('UNAUTHORIZED');
    }
    return Promise.reject(error);
});

const getEmitter = () => {
    return emitter;
}

export { restClient, getEmitter };