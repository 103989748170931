import CreateIcon from '@material-ui/icons/Create';
import CreateUserIcon from '@material-ui/icons/VerifiedUser';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NavigationOutlinedIcon from '@material-ui/icons/NavigationOutlined';
import ListIcon from '@material-ui/icons/List';

const icons = {
    NavigationOutlinedIcon,
    HomeOutlinedIcon,
    FormatListNumberedRtlIcon,
    CreateIcon,
    CreateUserIcon,
    ListIcon
};

export default {
    items: [
        {
            id: 'navigation',
            title: 'Bots',
            caption: 'Setup',
            type: 'group',
            icon: icons['NavigationOutlinedIcon'],
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    icon: icons['HomeOutlinedIcon'],
                    url: '/',
                },

                {
                    id: 'createBot',
                    title: 'Create bot',
                    type: 'item',
                    icon: icons['CreateIcon'],
                    url: '/create-bot',
                },
                {
                    id: 'listBots',
                    title: 'List bots',
                    type: 'item',
                    icon: icons['ListIcon'],
                    url: '/bots',
                },
                {
                    id: 'createUser',
                    title: 'Create User',
                    type: 'item',
                    icon: icons['CreateUserIcon'],
                    url: '/create-user',
                },
            ],
        }
    ],
};
